import $ from 'jquery';

function fixHeader() {
	$('#js-header').addClass('is-fixed');
	$('body').addClass('is-header-fixed');
	$('#js-header-features').slideUp('fast');
}

function unfixHeader() {
	$('#js-header').removeClass('is-fixed');
	$('body').removeClass('is-header-fixed');
	$('#js-header-features').slideDown('fast');
}

function offsetHeader() {
	const headerHeight = $('#js-header').outerHeight();
	$('body').css('padding-top', headerHeight);
}

$(() => {

	offsetHeader();

	let isMediumDown = window.matchMedia('(max-width: 1022px)');

	let scrolling = false;

	const headerHeight = $('#js-header').outerHeight();

	window.addEventListener('scroll', () => {
		// Below Bootstrap medium size, ensure header is unfixed but don't listen
		if(isMediumDown.matches) {
			unfixHeader();
			return false;
		}

		if(!scrolling) {
			window.requestAnimationFrame(() => {
				if(window.pageYOffset > headerHeight / 2) {
					fixHeader();
				}
				else {
					unfixHeader();
				}

				scrolling = false;
			});

			scrolling = true;
		}
	});

	window.addEventListener('resize', () => {
		offsetHeader();
	});

});
