import $ from 'jquery';

import { enableScroll, disableScroll } from '../utilities';

$( () => {
	if( sessionStorage && !sessionStorage.getItem('isshow') ){
		if( $('#js-first-visit-notice').length ){
			$('#js-first-visit-notice').addClass('c-first-visitor-notice--active');

			disableScroll();

			sessionStorage.setItem('isshow', true);

			$('#js-first-visit-notice__close').click( () => {
				$('#js-first-visit-notice').removeClass('c-first-visitor-notice--active');

				enableScroll();
			});
		}
	}
});
