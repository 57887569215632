import $ from 'jquery';
import 'bootstrap/js/dist/modal';

$(() => {

	if(!$('#js-configurator-modal').length) {
		return;
	}

	// Display popup when mouse leaves, if page doesn't already have popup open and popup wasn't already closed
	setTimeout(() => {
		$(document).on('mouseleave', () => {
			if(!$('body').hasClass('modal-open') && !sessionStorage.getItem('sheds_configurator_modal_hidden')) {
				$('#js-configurator-modal').modal();
			}
		});
	}, 20000)

	// Set session flag when popup closed
	$('#js-configurator-modal').on('hidden.bs.modal', () => {
		sessionStorage.setItem('sheds_configurator_modal_hidden', true);
	});

});
