import $ from 'jquery';
import Flickity from 'flickity';
import 'flickity-fade';

$(() => {

	$('.js-banner-gallery').each((index, gallery) => {
		new Flickity(gallery, {
			accessibility: false,
			autoPlay: true,
			draggable: false,
			fade: true,
			pageDots: false,
			pauseAutoPlayOnHover: false,
			prevNextButtons: false,
			setGallerySize: false
		});
	});

});
