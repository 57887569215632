import $ from "jquery";
$(() => {
	// Cart checkout. Restart configurator
	$(".start-again-btn").on("click", function (e) {
		e.preventDefault();
		window.location.href = "/design-online";
	});

	
	$(document).ready(function () {

		// Cart checkout. Hide country fields as they are always set as UK
		$('#billing_country_field').hide();
		$('#shipping_country_field').hide();

		// Cart checkout. Hide the county field  on checkout page
		$('#billing_state_field').hide();
		$('#shipping_state_field').hide();

		let html = '<div class="cstm-billing-country form-row form-row-wide">';
		html += '<p>Country</p>';
		html += '<p class="cstm-billing-country__name">United Kingdom (UK)</p>';
		html += '</div>';

		// Cart checkout. Add country data to billing & shipping form
		$('#billing_postcode_field').after(html);
		$('#shipping_postcode_field').after(html);
	});
});
