import $ from 'jquery';

$(() => {
	// Define small device query
	let isSmallMQ = window.matchMedia('(max-width: 1023px)');

	if ( isSmallMQ.matches ){
		$('li.c-menu__item.menu-item-has-children .c-menu__chevron').click(() => {
			$(event.currentTarget).parent('li.c-menu__item.menu-item-has-children').toggleClass('active');
		})
	} else {
		$('li.c-menu__item.menu-item-has-children').mouseover(() => {
			$(event.currentTarget).addClass('active');
		})
		$('li.c-menu__item.menu-item-has-children').mouseleave(() => {
			$(event.currentTarget).removeClass('active');
		})
	}
});
