import $ from 'jquery';

import { enableScroll, disableScroll } from '../utilities';

$(() => {

	// Toggle menu visibility on button click
	$('.js-menu-button').click(event => {
		$(event.currentTarget).toggleClass('is-menu-open');

		const $nav = $('#js-menu');

		// Hide menu
		if($nav.hasClass('is-active')) {
			$nav.fadeOut('fast').removeClass('is-active');

			enableScroll();
		}
		// Show menu
		else {
			$nav.fadeIn('fast').addClass('is-active');

			disableScroll();
		}
	});

});
