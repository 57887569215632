import $ from 'jquery';

$(() => {
	const header = document.querySelector('#js-header');

	if(!header) {
		return;
	}

	const isSmallMQ = window.matchMedia('(max-width: 1023px)');

	let elHeight		= 0,
		elTop			= 0,
		dHeight			= 0,
		wHeight			= 0,
		wScrollCurrent	= 0,
		wScrollBefore	= 0,
		wScrollDiff		= 0;

	window.addEventListener('scroll', () => {
		if(!isSmallMQ.matches) {
			return;
		}

		elHeight		= header.offsetHeight;
		dHeight			= document.body.offsetHeight;
		wHeight			= window.innerHeight;
		wScrollCurrent	= window.pageYOffset;
		wScrollDiff		= wScrollBefore - wScrollCurrent;
		elTop			= parseInt(window.getComputedStyle(header).getPropertyValue('top')) + wScrollDiff;

		// Only show promo on mobile if near top of screen
		if(wScrollCurrent <= 250) {
			header.classList.add('has-visible-promo');
		}
		else {
			header.classList.remove('has-visible-promo');
		}

		// scrolled to the very top; element sticks to the top
		if(wScrollCurrent <= 0) {
			header.style.top = '0px';
		}
		// scrolled up; element slides in
		else if(wScrollDiff > 0) {
			header.style.top = (elTop > 0 ? 0 : elTop) + 'px';
		}
		// scrolled down
		else if(wScrollDiff < 0) {
			// scrolled to the very bottom; element slides in
			if(wScrollCurrent + wHeight >= dHeight - elHeight) {
				header.style.top = ((elTop = wScrollCurrent + wHeight - dHeight) < 0 ? elTop : 0) + 'px';
			}
			// scrolled down; element slides out
			else {
				header.style.top = (Math.abs(elTop) > elHeight ? -elHeight : elTop) + 'px';
			}
		}

		wScrollBefore = wScrollCurrent;
	});
});
