import $ from 'jquery';
import Flickity from 'flickity';

$(() => {

	const $testimonials = $('.js-testimonials');

	if($testimonials.length && $testimonials.children.length > 1) {

		$testimonials.removeClass('js-first-slide-visible');

		new Flickity('.js-testimonials', {
			prevNextButtons: true,
			wrapAround: true
		});

	}

});
